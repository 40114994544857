import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { MainComponent } from './core/components/main.component';
import { UserResolver } from './app-resolvers';
import { DefaultRouteComponent } from './shared/components/default-route.component';
import { LoginComponent } from './shared/components/login.component';
import { GuardsAclService } from './guards/guards-acl.service';
import { AiFriendConversationComponent } from '@proman/ai/ai-friend-conversation.component';

const routes: Routes = [
    { path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule) },
    { path: 'public', loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule) },
    { path: 'login', component: LoginComponent, },
    { path: '', component: MainComponent, resolve: { user: UserResolver },
        children: [
            { path: '', pathMatch: 'full', component: DefaultRouteComponent },
            { path: 'customers', canActivate: [GuardsAclService], data: { acl: 'customer.display' }, loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
            { path: 'suppliers', loadChildren: () => import('./suppliers/suppliers.module').then(m => m.SuppliersModule) },
            { path: 'functions', canActivate: [GuardsAclService], data: { acl: ['workplace.edit', 'article.edit'] }, loadChildren: () => import('./expressions/expressions.module').then(m => m.ExpressionsModule) },
            { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule), canActivate: [GuardsAclService], data: { acl: 'employee.view_all' } },
            { path: 'maintenance', canActivate: [GuardsAclService], data: { acl: 'maintenance.display' }, loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule) },
            { path: 'subcontractors', loadChildren: () => import('./subcontractors/subcontractors.module').then(m => m.SubcontractorsModule) },
            { path: 'system-settings', canActivate: [GuardsAclService], data: { acl: 'system_options.view' }, loadChildren: () => import('./system/system-settings.module').then(m => m.SystemSettingsModule) },
            { path: 'dynamic-tables', loadChildren: () => import('./dynamic_tables/dynamic-tables.module').then(m => m.DynamicTablesModule) },
            { path: 'reports', canActivate: [GuardsAclService], data: { acl: 'reports.view', redirect: '' }, loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
            { path: 'parameters', loadChildren: () => import('./parameters/parameters.module').then(m => m.ParametersModule) },
            { path: 'dimensions', canActivate: [GuardsAclService], data: { acl: 'material.display' }, loadChildren: () => import('./dimensions/dimensions.module').then(m => m.DimensionsModule) },
            { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [GuardsAclService], data: { acl: 'employee.view_all' } },
            { path: 'qr', loadChildren: () => import('./qr/qr.module').then(m => m.QrModule) },
            { path: 'sales-opportunities', loadChildren: () => import('./projects/sale-opportunities.module').then(m => m.SaleOpportunitiesModule) },
            { path: 'dev-projects', canActivate: [GuardsAclService], data: { acl: 'development_project.display' }, loadChildren: () => import('./dev_projects/dev-projects.module').then(m => m.DevProjectsModule) },
            { path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
            { path: 'events', canActivate: [GuardsAclService], data: { acl: 'event.display' }, loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
            { path: 'carriers', loadChildren: () => import('./carriers/carriers.module').then(m => m.CarriersModule) },
            { path: 'files', loadChildren: () => import('./files/files-browser.module').then(m => m.FilesBrowserModule) },
            { path: '', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
            { path: '', loadChildren: () => import('./production/production.module').then(m => m.ProductionModule) },
            { path: '', loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule) },
            { path: '', loadChildren: () => import('./materials/materials.module').then(m => m.MaterialsModule) },
            { path: '', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
            { path: '', loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule) },
            { path: '', loadChildren: () => import('./workplaces/workplaces.module').then(m => m.WorkplacesModule) },
            { path: '', loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule) },
            { path: 'ai-friend', component: AiFriendConversationComponent },
            { path: '**', redirectTo: '', }
        ]
    },
    { path: '**', component: DefaultRouteComponent, }
];
@NgModule({
    imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
