import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { CONFIG } from '../config';

@Injectable({
  providedIn: 'root'
})
export class AiFriendService {

  constructor(
    private Request: RequestService,
  ) {
  }

  askFriend = (question: string, conversationId?: number) => {
    const body = { question };
    if (conversationId > 0) body['id'] = conversationId;
    return this.Request.post(`${CONFIG.api}smarton_prompt/ask_friend`, body)
  }

}
